<template>
  <div class="user-service-checkout">
    <user-service-calender />
  </div>
</template>

<script>
import UserServiceCalender from './UserServiceCalender.vue'

export default {
  components: {
    UserServiceCalender,
  },
}
</script>

<style scoped></style>
