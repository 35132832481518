<template>
  <div
    class="user-service-calender"
    :class="{ 'payment-form-done-bg': showOrderPlacedForm, 'text-center': paymentSuccessfull, 'text-center': showPaymentPending || paymentFailed }"
  >
    <div
      v-if="cartDetails && cartDetails.length > 0"
      class="user-service-calender app-calendar overflow-hidden"
      style="border-top-left-radius: 0"
    >
      <user-addresses
        ref="userAddresses"
        class="d-none"
        @get-store-list="addressSelected"
      />
      <b-modal
        v-model="isPromocodeVisible"
        :title="$t('Promocode')"
        :ok-title="$t('Apply')"
        :no-close-on-backdrop="true"
        @hidden="cancelPromocode"
      >
        <div class="row">
          <div class="col-md-12">
            <b-form-group :label="$t('Enter Coupon Code')">
              <div class="row">
                <div class="col-8">
                  <b-form-input
                    v-model="paymentInfo.couponCode.promo_code"
                    type="text"
                    disabled
                  />
                </div>
                <div class="col-3">
                  <b-button @click="okPromocode" variant="primary" :disabled="paymentInfo.couponCode.promo_code == null || paymentInfo.couponCode.promo_code == ''">
                    {{ $t('Apply') }}
                  </b-button>
                </div>
              </div>
            </b-form-group>
          </div>
          <div
            v-for="(coupon, index) of promocodeList"
            :key="index"
            class="col-md-12"
          >
            <b-card
              class="cursor-pointer"
              @click="applyPromocode(coupon)"
            >
              <b-card-header class="flex-column align-items-start px-0">
                <b-card-title style="word-break: break-all;">
                  {{ coupon.promo_description }}
                </b-card-title>
              </b-card-header>
              <b-card-body class="p-0">
                <div class="row">
                  <div class="col-7">
                    <span
                      class="badge badge-primary badge-pill mr-1 mb-1"
                      style="padding: 10px 20px; font-size: 20px"
                    >{{ coupon.promo_code }}</span>
                  </div>
                  <div class="col-5">
                    <img
                      :src="$helpers.imageHelper(coupon.picture)"
                      class="w-100"
                    >
                  </div>
                </div>
                <!-- <p class="mb-0 small">
                  {{ $t('Valid Till') }}: {{ coupon.expiration }}
                </p> -->
              </b-card-body>
            </b-card>
          </div>
        </div>
      </b-modal>
      <div class="row">
        <div class="col-md-8">
          <div class="position-relative">
            <p class="text-center">
              {{ $t('Please select your preferred service location') }}
            </p>
            <div class="row d-flex justify-content-around mb-3 user-service-location-picker">
              <div
                class="wrap-top-search-bar mr-0 d-flex align-items-center cursor-pointer responsive-margin-bottom-checkout"
                :class="{ 
                  'disable-effect': providerUserAddress.provider_address && providerUserAddress.provider_address.onsite === 0,
                  'border-primary': activeAddress.user
                }"
                @click="setAddressType('user')"
              >
                <img
                  :src="require('@/assets/images/app-icons/user/home.png')"
                  class="ml-2"
                  style="width: 50px"
                >
                <div class="wrap-address d-block w-100 text-center m-auto">
                  <p class="mb-25">
                    {{ $t('My Location') }}
                  </p>
                  <p class="mb-25">
                    {{ $t('Flat/Villa No') }}: {{ providerUserAddress.user_address.flat_no }}
                  </p>
                  <p class="mb-25">
                    {{ $t('Building') }}: {{ providerUserAddress.user_address.building }}
                  </p>
                  <p class="mb-25">
                    {{ providerUserAddress.user_address.map_address }}
                  </p>
                </div>
                <feather-icon
                  icon="ChevronDownIcon"
                  size="32"
                  @click="showAddressSelection"
                />
              </div>
              <div
                class="wrap-top-search-bar mr-0 d-flex align-items-center cursor-pointer"
                :class="{ 
                  'disable-effect': providerUserAddress.provider_address && providerUserAddress.provider_address.onsite === 1,
                  'border-primary': activeAddress.provider
                }"
                @click="setAddressType('provider')"
              >
                <img
                  :src="require('@/assets/images/app-icons/user/office.png')"
                  class="ml-2"
                  style="width: 50px"
                >
                <div class="wrap-address d-block w-100 text-center m-auto">
                  <p class="mb-25">
                    {{ $t('Provider Location') }}
                  </p>
                  <p class="mb-25">
                    {{ providerUserAddress.provider_address.current_location }}
                  </p>
                </div>
              </div>
            </div>
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0 mobile-px-0">
                <div class="col-md-7 mx-auto mobile-px-0">
                  <custom-calender
                    ref="calenderRef"
                    :hide-submit="true"
                    :timing="storeCurrentTimings"
                    :all-timings="providerTimings"
                    :store-slot-info="providerTimings"
                    @submit="bookTableOnline"
                  />
                </div>
                <!-- <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-1 service-checkout-right-section">
          <b-card class="service-checkout-card">
            <p
              class="mb-05 pointer cursor-pointer text-center"
              @click="showAddressSelection"
            >
              {{ $t('Choose Location') }}
            </p>
            <div class="delivery-location">
              <p class="small mb-05">
                {{ $t('SERVICE LOCATION') }}
              </p>
              <p
                v-if="activeAddress.user"
                class="mb-25"
              >
                {{ $t('Flat No') }}: {{ deliveryLocation.flat_no }}
              </p>
              <p
                v-if="activeAddress.user"
                class="mb-25"
              >
                {{ $t('Street') }}: {{ deliveryLocation.street }}
              </p>
              <p
                v-if="activeAddress.user"
                class="mb-0"
              >
                {{ deliveryLocation.map_address }}
              </p>
              <p
                v-if="providerUserAddress.provider_address && providerUserAddress.provider_address.onsite === 0"
                style="font-weight: bold"
                class="font-16"
              >
                <span>{{ $t('Service is only available at Provider location') }}</span>
              </p>
              <p
                v-if="activeAddress.provider"
                class="mb-0"
              >
                {{ $t('Location') }}: {{ deliveryLocation.current_location }}
              </p>
              <hr>
            </div>
            <div class="delivery-location">
              <p class="small mb-05">
                {{ $t('SERVICES') }}
              </p>
              <table class="table table-borderless">
                <tr
                  v-for="(cart, index) of cartDetails"
                  :key="index"
                >
                  <td class="pl-0 text-black pt-pb-5">
                    {{ cart.provider_service.main_service ? (!isEmpty(cart.provider_service.title) ? cart.provider_service.title : cart.provider_service.main_service.service_name) : '' }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ cart.service_price }} {{ userCurrency }} x {{ cart.quantity }}
                  </td>
                </tr>
              </table>

              <hr>
            </div>
            <div class="trip-fare">
              <table class="table table-borderless">
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Total Service Price') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.total_item_price }} {{ userCurrency }}
                  </td>
                </tr>
                <tr v-if="orderInvoice.shop_discount > 0">
                  <td class="pl-0 text-success pt-pb-5">
                    {{ $t('Provider Offer') }}
                  </td>
                  <td class="text-success pt-pb-5 text-right">
                    {{ orderInvoice.provider_discount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Provider Tax') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.provider_gst_amount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('Service Location') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ !activeAddress.user ? 'At provider location' : 'At user location' }}
                  </td>
                </tr>
                <tr v-if="orderInvoice.promocode_id > 0">
                  <td class="pl-0 text-success pt-pb-5">
                    {{ $t('Coupon') }} - ({{ promocodeName }})
                  </td>
                  <td class="text-success pt-pb-5 text-right">
                    {{ orderInvoice.promocode_amount }} {{ userCurrency }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="p-0"
                    colspan="2"
                  >
                    <hr>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0 text-black pt-pb-5">
                    {{ $t('GRAND TOTAL') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    {{ orderInvoice.net }} {{ userCurrency }}
                  </td>
                </tr>
                <!-- <tr>
                  <td class="pl-0 text-black py-0">
                    {{ $t('Payment Mode') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    <b-form-group class="payment-mode mb-0">
                      <select
                        v-model="paymentMode"
                        class="form-control"
                      >
                        <option
                          v-for="(item, index) of optionsLabel"
                          :key="index"
                          :value="item.value"
                        >
                          {{ item.label }}
                        </option>
                      </select>
                    </b-form-group>
                  </td>
                </tr> -->
                <!-- <tr v-if="paymentMode === 'CARD'">
                  <td class="pl-0 text-black py-0">
                    {{ $t('Select Card') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    <b-form-group class="payment-mode mb-0">
                      <select
                        v-model="paymentCardInfo"
                        class="form-control"
                      >
                        <option value="">
                          {{ $t('Select Card') }}
                        </option>
                        <option
                          v-for="(x, index) of userCards"
                          :key="index"
                          :value="x.value"
                        >
                          {{ $t('Card ends with') }} XXXX-XXXX-XXXX-{{ x.last_four }} {{ $t('for') }} {{ x.holder_name }}
                        </option>
                      </select>
                    </b-form-group>
                  </td>
                </tr> -->
                <!-- <tr v-if="paymentMode === 'CARD'">
                  <td class="pl-0 text-black py-0">
                    {{ $t('Payment Type') }}
                  </td>
                  <td class="text-black pt-pb-5 text-right">
                    <b-form-group class="payment-mode mb-0">
                      <b-form-select
                        v-model="paymentInfo.paymentType"
                        :options="paymentTypeList"
                      />
                    </b-form-group>
                  </td>
                </tr> -->
                <tr>
                  <td
                    class="px-0"
                    colspan="2"
                  >
                    <b-form-group :label="$t('Promo')">
                      <b-form-group class="wrapper-payment">
                        <b-img
                          class="common-width-images"
                          :src="require('@/assets/images/payment/Promo.png')"
                        />
                        <b-form-radio
                          v-model="paymentInfo.applyPromocode"
                          name="promocode-type-radio"
                          :value="'YES'"
                          @change="promocodeChanged"
                        >
                          {{ $t('Promo Code') }}
                        </b-form-radio>
                      </b-form-group>
                      <b-badge
                        v-if="paymentInfo.isPromocodeApplied"
                        pill
                        variant="light-primary"
                        class="ml-1"
                        @click="removePromocode"
                      >
                        {{ $t('Remove Promocode') }}
                      </b-badge>
                    </b-form-group>
                  </td>
                  <td
                    class="d-none"
                    colspan="2"
                  >
                    <p
                      v-if="!paymentInfo.isPromocodeApplied"
                      class="cursor-pointer"
                      @click="showPromocode"
                    >
                      <b-img
                        :src="$helpers.imageHelper('http://staging.getsuperapp.com/svg/coupon.svg')"
                        width="21"
                        class="mr-75"
                      />
                      <span class="align-middle">{{ $t('Add Promocode') }}</span>
                    </p>
                    <p
                      v-if="paymentInfo.isPromocodeApplied"
                      class="cursor-pointer"
                    >
                      <b-img
                        :src="$helpers.imageHelper('http://staging.getsuperapp.com/svg/coupon.svg')"
                        width="21"
                        class="mr-75"
                      />
                      <span class="align-middle">{{ $t('Promocode Applied') }}: {{ paymentInfo.couponCode.promo_code }}</span>
                      <b-badge
                        pill
                        variant="light-primary"
                        class="ml-1"
                        @click="removePromocode"
                      >
                        {{ $t('Remove Promocode') }}
                      </b-badge>
                    </p>
                  </td>
                </tr>
                <tr v-if="showPaymentOptions">
                  <td
                    class="px-0"
                    colspan="2"
                  >
                    <b-form-group>
                      <label class="col-form-label pt-0 pb-0">{{ $t('Payment Option') }}</label>
                      <p class="mb-1 small">
                        {{ $t('Be sure to click on correct payment option') }}
                      </p>
                      <!-- <div
                        v-if="orderInvoice.wallet_amount"
                        class="d-flex align-items-center wrapper-payment"
                      >
                        <b-img
                          class="common-width-images"
                          :title="'Wallet'"
                          :src="require('@/assets/images/app-icons/wallet.png')"
                          :class="{ active: paymentMode === 'WALLET' }"
                        />
                        <b-form-radio
                          v-model="paymentMode"
                          name="payment-mode-radio"
                          :value="'WALLET'"
                        >
                          {{ $t('Wallet') }}: {{ orderInvoice.wallet_amount }} {{ userCurrency }}
                        </b-form-radio>
                      </div> -->
                      <div
                        v-if="orderInvoice.cash"
                        class="d-flex align-items-center wrapper-payment"
                      >
                        <b-img
                          class="common-width-images"
                          :title="'Cash'"
                          :src="require('@/assets/images/payment/Cash.png')"
                          :class="{ active: paymentMode === 'CASH' }"
                        />
                        <b-form-radio
                          v-model="paymentMode"
                          name="payment-mode-radio"
                          :value="'CASH'"
                        >
                          {{ $t('Cash') }}
                        </b-form-radio>
                      </div>
                      <div
                        v-if="orderInvoice.card"
                        class="d-flex align-items-center wrapper-payment"
                      >
                        <b-img
                          class="common-width-images"
                          :title="'Card'"
                          :src="require('@/assets/images/payment/Card payment.png')"
                          :class="{ active: paymentMode === 'CARD' }"
                        />
                        <b-form-radio
                          v-model="paymentMode"
                          name="payment-mode-radio"
                          :value="'CARD'"
                        >
                          {{ $t('Card') }}
                        </b-form-radio>
                      </div>
                      <div class="d-flex align-items-center wrapper-payment">
                        <b-img
                          class="common-width-images"
                          :title="'Card On Delivery'"
                          :src="require('@/assets/images/app-icons/CARD_ON_DELIVERY.png')"
                          :class="{ active: paymentMode === 'on_delivery' }"
                        />
                        <b-form-radio
                          v-model="paymentMode"
                          name="payment-mode-radio"
                          :value="'on_delivery'"
                        >
                          {{ $t('Card On Delivery') }}
                        </b-form-radio>
                      </div>
                      <b-form-select
                        v-model="paymentMode"
                        class="d-none"
                        :options="optionsLabel"
                      />
                    </b-form-group>
                  </td>
                </tr>
                <tr v-if="showPaymentOptions && paymentMode === 'CARD'">
                  <td class="px-0 px-0">
                    <b-form-group
                      :label="$t('Payment Type')"
                      class="wrapper-payment-info-type"
                    >
                      <b-form-group
                        v-for="(item, index) of paymentTypeList.filter(x => x.value)"
                        :key="index"
                        class="wrapper-payment"
                      >
                        <b-form-radio
                          v-model="paymentInfo.paymentType"
                          name="payment-type-radio"
                          :value="item.value"
                        >
                          <b-img
                            :title="item.text"
                            class="common-width-images"
                            :src="item.icon"
                            :class="{ 'w-80px': item.class === 'w-80px', active: paymentInfo.paymentType === item.value, 'w-75px': item.value === 'paypal' }"
                            @click="paymentInfo.paymentType = item.value"
                          />
                        </b-form-radio>
                      </b-form-group>
                      <b-form-select
                        v-model="paymentInfo.paymentType"
                        class="d-none"
                        :options="paymentTypeList"
                      />
                    </b-form-group>
                  </td>
                </tr>
              </table>

              <div class="relative mt-2 text-center">
                <hr>
                <b-button
                  size="lg"
                  variant="primary"
                >
                  <span
                    class="mr-25 align-middle"
                    @click="placeOrder"
                  >{{ $t('Confirm') }}</span>
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>
    <div
      v-else-if="showOrderPlacedForm"
      class="payment-form-done-bg"
    >
      <order-placed-successfully
        :order-number="orderNumber"
        :title="'Service booked successfully'"
        :order-text="'Your service booking number is'"
        :is-provider="true"
        :sub-title="'We have successfully placed your order. Provider will soon accept your order'"
        @go-to-order="goToOrder"
      />
      <div class="col-md-12 text-center">
        <b-button
          variant="success"
          class="mb-2 mx-1"
          :to="{
            name: 'apps-service-provider',
            params: {
              serviceName: providerData.slug
            }
          }"
        >
          {{ $t('Book Again') }}
        </b-button>
      </div>
      <div class="col-md-12 text-center">
        <b-button
          variant="primary"
          class="mb-2 mx-1"
          @click="goToOrder"
        >
          {{ $t('Check Service Status') }}
        </b-button>
      </div>
    </div>
    <div
      v-else-if="paymentSuccessfull"
      class="payment-successfull card"
    >
      <div style="border-radius: 200px; height: 200px; width: 200px; background: #f8faf5; margin: 0 auto">
        <i class="checkmark">✓</i>
      </div>
      <h1 class="mt-2">
        {{ $t('Payment Successful') }}
      </h1>
      <p>
        {{ $t('We have successfully recieved your payment') }}
      </p>
    </div>
    <div
      v-else-if="showPaymentPending"
      class="payment-successfull card"
    >
      <h1 class="mt-2">
        {{ $t('Please wait') }}
      </h1>
      <p>
        {{ $t('We are processing, please complete the payment') }}
      </p>
    </div>
    <div
      v-else-if="paymentFailed"
      class="payment-successfull card"
    >
      <payment-retry
        :payment-options="paymentOptions"
        @payment-type-selected="paymentMethodChanged"
      />
      <div
        :class="{ 'disable-effect': retryPaymentMethod === 'CASH' }"
        class="wrapper-payment-methods wrapper-payment-info-type-retry"
      >
        <b-form-group
          v-for="(item, index) of paymentTypeListRetry.filter(x => x.value)"
          :key="index"
          class="wrapper-payment"
        >
          <b-form-radio
            v-model="paymentSelected"
            name="payment-type-radio"
            :value="item.value"
          >
            <b-img
              :title="item.text"
              class="common-width-images"
              :src="item.icon"
              :class="{ 'w-80px': item.class === 'w-80px', active: paymentSelected === item.value, 'w-75px': item.value === 'paypal' }"
              @click="paymentSelected = item.value"
            />
          </b-form-radio>
        </b-form-group>
      </div>
      <div
        v-if="paymentSelected"
        class="mt-2"
      >
        <button
          class="btn btn-primary"
          @click="beginPayment(paymentSelected)"
        >
          {{ $t('Continue Payment') }}
        </button>
      </div>
    </div>
    <div v-else>
      <no-data-available :type="'cart'" />
      <div class="col-md-12 text-center my-2">
        <b-link
          class="btn btn-primary"
          :to="{ path: '/user/dashboard' }"
        >
          {{ $t('Book service now') }}
        </b-link>
      </div>
    </div>
    <stripe-payment
      ref="stripePaymentModule"
      :that="that"
      :admin-service="'SERVICE'"
      @payment-done="paymentDone"
      @form-cancelled="formCancelled"
      @order-placed-successfully="orderPlaced"
    />
  </div>
</template>

<script>
import { BCard, BFormSelect, BFormGroup, BFormRadio, BLink, BButton, BModal, BFormInput, BCardHeader, BCardTitle, BCardBody, BImg, BBadge } from 'bootstrap-vue'
import { hideLoader, showLoader, showDangerNotification, showErrorNotification, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import UserAddresses from '@/@core/layouts/shared/UserAddresses.vue'
import eventBus from '@/libs/event-bus'
import moment from 'moment'
import PaymentRetry from '@/views/shared/PaymentRetry.vue'
import OrderPlacedSuccessfully from '../shared/OrderPlacedSuccessfully.vue'
import { useUserUi } from '../useUser'
import StripePayment from '../shared/StripePayment.vue'
import CustomCalender from '../shared/CustomCalender.vue'
import NoDataAvailable from '../shared/NoDataAvailable.vue'
import {isEmpty} from '@/utilities'
export default {
  components: {
    StripePayment,
    PaymentRetry,
    BFormRadio,
    BCard,
    BFormGroup,
    OrderPlacedSuccessfully,
    BLink,
    BFormSelect,
    NoDataAvailable,
    BButton,
    BModal,
    BFormInput,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BImg,
    BBadge,
    CustomCalender,
    UserAddresses,
  },
  data() {
    return {
      showPaymentOptions: false,
      paymentSuccessfull: false,
      selectedAddress: null,
      needPayment: false,
      orderId: '',
      orderNumber: '',
      orderCreatedResponse: null,
      showPaymentPending: false,
      storeCurrentTimings: null,
      paymentPending: false,
      paymentFailed: false,
      showOrderPlacedForm: false,
      paypalPaymentData: {},
      phonePePayment: {},
      retryPaymentMethod: 'CARD',
      paymentSelected: '',
      fullCartInfo: {},
      paymentCardInfo: '',
      paymentInfo: {
        couponCode: {},
        paymentMode: 'CASH',
        useWalletOption: false,
        isPromocodeApplied: false,
        orderType: 'DELIVERY',
        paymentType: '',
        cardId: '',
        applyPromocode: false,
      },
      promocodeList: [],
      isPromocodeVisible: false,
      paymentMode: 'CASH',
      optionsLabel: [
        {
          label: 'Cash',
          value: 'CASH',
        },
        {
          label: 'Card',
          value: 'CARD',
        },
        {
          label: 'Card on Delivery',
          value: 'CARD_ON_DELIVERY',
        },
      ],
      bookedTimeSlotData: {},
      calendarsColor: {
        AtStore: 'primary',
        AtCustomer: 'success',
      },
      providerUserAddress: {
        provider_address: {},
        user_address: {},
      },
      bookedTimeSlots: [],
      activeAddress: {
        user: false,
        provider: false,
      },
      userCards: [],
      providerTimings: [],
      providerData: {}
    }
  },
  computed: {
    that() {
      return this
    },
    entireCart() {
      return this.$store.state['app-ecommerce'].entireCart
    },
    paymentOptions() {
      return {
        cash: 1,
        card: 1,
      }
    },
    promocodeName() {
      const cart = this.entireCart
      if (cart && cart.promocode_id && this.promocodeList) {
        const isExist = this.promocodeList.find(x => x.id === cart.promocode_id)
        if (isExist) {
          return isExist.promo_code
        }
      }
      return ''
    },
    paymentTypeList() {
      const paymentTypeList = [
        {
          text: 'Select Payment Type',
          value: '',
        },
      ]
      const cardData = this.entireCart
      if (cardData.stripe) {
        paymentTypeList.push({
          text: 'Stripe',
          value: 'stripe',
          icon: `${this.$apiUrl}/images/icons/stripe.svg`,
        })
      }
      if (cardData.paypal) {
        paymentTypeList.push({
          text: 'Paypal',
          value: 'paypal',
          icon: `${this.$apiUrl}/images/icons/paypal.svg`,
        })
      }
      if (cardData.phonepe) {
        paymentTypeList.push({
          text: 'PhonePe',
          value: 'phonepe',
          icon: `${this.$apiUrl}/images/icons/phonepe.svg`,
          class: 'w-80px',
        })
      }
      if (cardData.razor) {
        paymentTypeList.push({
          text: 'Razorpay',
          value: 'razor',
          icon: `${this.$apiUrl}/images/icons/razorpay.svg`,
          class: 'w-80px',
        })
      }
      return paymentTypeList
    },
    paymentTypeListRetry() {
      const paymentMethods = []
      if (this.orderCreatedResponse && this.orderCreatedResponse.payments) {
        const payment = this.orderCreatedResponse.payments
        if (payment.stripe) {
          paymentMethods.push({
            text: 'Stripe',
            value: 'stripe',
            icon: `${this.$apiUrl}/images/icons/stripe.svg`,
          })
        }
        if (payment.paypal) {
          paymentMethods.push({
            text: 'Paypal',
            value: 'paypal',
            icon: `${this.$apiUrl}/images/icons/paypal.svg`,
          })
        }
        if (payment.phonepe) {
          paymentMethods.push({
            text: 'PhonePe',
            value: 'phonepe',
            icon: `${this.$apiUrl}/images/icons/phonepe.svg`,
            class: 'w-80px',
          })
        }
        if (payment.razor) {
          paymentMethods.push({
            text: 'Razor Pay',
            value: 'razor',
            icon: `${this.$apiUrl}/images/icons/razorpay.svg`,
            class: 'w-80px',
          })
        }
        if (payment.flutterwave) {
          paymentMethods.push({
            text: 'Flutterwave',
            value: 'flutterwave',
            icon: require('@/assets/images/app-icons/sub-menu/Logo_FullColor.png'),
            class: 'w-80px',
          })
        }
      }
      return paymentMethods
    },
    cartDetails() {
      return this.$store.state['app-ecommerce'].cartItems
    },
    orderDetails() {
      return this.$store.state['app-ecommerce'].entireCart
    },
    deliveryLocation() {
      if (!this.providerUserAddress) return {}
      if (this.activeAddress.user && this.providerUserAddress.user_address) return this.providerUserAddress.user_address
      if (this.activeAddress.provider && this.providerUserAddress.provider_address) return this.providerUserAddress.provider_address
      return {}
    },
    orderInvoice() {
      if (!this.orderDetails) return {}
      return this.orderDetails
    },
    userCurrency() {
      const cart = this.cartDetails
      if (cart && cart[0] && cart[0].provider) {
        return cart[0].provider.currency_symbol
      }
      return ''
    },
    storeType() {
      if (this.orderDetails && this.orderDetails.store && this.orderDetails.store.storetype) {
        return this.orderDetails.store.storetype
      }
      return ''
    },
    storeTimings() {
      if (!this.providerTimings) return null
      return this.providerTimings.find(x => x.day === this.currentDay)
    },
    currentDay() {
      return moment().format('ddd').toUpperCase()
    },
    getProviderSlug() {
      if (this.orderDetails) {
        if (this.orderDetails.provider_slug) {
          return this.orderDetails.provider_slug
        }
      }
      if (this.cartDetails && this.cartDetails[0] && this.cartDetails[0].provider && this.cartDetails[0].provider.slug) {
        return this.cartDetails[0].provider.slug
      }
      return ''
    },
  },
  watch: {
    entireCart: {
      immediate: true,
      handler() {
        // const { entireCart } = this.$store.state['app-ecommerce']
        // if (entireCart) {
        //   if (entireCart.cash) {
        //     this.paymentMode = 'CASH'
        //   } else if (entireCart.card) {
        //     this.paymentMode = 'CARD'
        //   } else {
        //     this.paymentMode = 'on_delivery'
        //   }
        // }
        this.paymentMode = 'CASH'
      },
    },
    getProviderSlug: {
      immediate: true,
      handler() {
        this.getProviderTimings()
        this.getProviderUserAddress()
      },
    },
  },
  mounted() {
    if (this.$route.query.token && this.$route.query.PayerID) {
      this.paymentSuccessfull = true
    }
    this.$nextTick(() => {
      if (localStorage.getItem('providerUserSelectedAddress')) {
        localStorage.setItem('userSelectedAddress', localStorage.getItem('providerUserSelectedAddress'))
      }
      if (window.SuperApp.actions.selectedUserAddress()) {
        this.getProviderUserAddress()
        // this.getServiceTimeslots()
        this.getUserPromocode()
        this.getUserCardList()
      } else if (this.$refs.userAddresses) {
        // this.$refs.userAddresses.showSelectAddress()
      }
    })
    window.stripePaymentModule = this.$refs.stripePaymentModule
    this.getOrderDetailById()
    this.getPromoCodeData()
  },
  methods: {
    isEmpty,
    orderPlaced() {
      this.showOrderPlacedForm = true
      this.needPayment = false
      this.getOrderDetailById()
    },
    paymentMethodChanged(payload) {
      this.paymentSelected = ''
      this.retryPaymentMethod = payload
      if (payload === 'CASH') {
        this.paymentSelected = 'cash'
      }
    },
    getOrderId() {
      // eslint-disable-next-line no-nested-ternary
      return this.orderId ? this.orderId : this.$route.query.orderId ? this.$route.query.orderId : window.SuperApp.helper.getParams('orderId')
    },
    getOrderDetailById() {
      if (!this.getOrderId()) {
        return
      }
      const { userServiceRequest } = useUserUi()
      showLoader()
      userServiceRequest(this.getOrderId())
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.orderNumber = data.responseData.service.booking_id
            this.orderCreatedResponse = data.responseData.service
            this.orderId = this.orderCreatedResponse.id
            this.providerData = this.orderCreatedResponse.provider
            if (this.orderCreatedResponse.payment_mode === 'CARD' && this.orderCreatedResponse.status === 'WAITING_FOR_PAYMENT') {
              this.showPaymentFailed()
            } else {
              this.showOrderPlacedForm = true
              this.needPayment = false
            }
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    formCancelled() {
      this.showPaymentFailed()
    },
    showAddressSelection() {
      if (this.$refs.userAddresses) {
        this.$refs.userAddresses.showSelectAddress()
      }
    },
    addressSelected() {
      this.getProviderUserAddress()
      // this.getServiceTimeslots()
      this.getUserPromocode()
      this.getUserCardList()
    },
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getProviderTimings() {
      if (!this.getProviderSlug) {
        return
      }
      const { getProviderTimings } = useUserUi()
      getProviderTimings(`?provider_id=${this.getProviderSlug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    retryPayment(paymentMethod) {
      showLoader()
      const { retryPayment, makeFlutterwavePayment } = useUserUi()
      const formData = new FormData()
      formData.append('payment_method', paymentMethod)
      formData.append('admin_service', 'SERVICE')
      formData.append('order_id', this.getOrderId())
      if (paymentMethod === 'stripe') {
        if (this.orderCreatedResponse && this.orderCreatedResponse.payments && this.orderCreatedResponse.payments.stripe_publishable_key) {
          formData.append('stripeToken', this.orderCreatedResponse.payments.stripe_publishable_key)
        }
      }
      retryPayment(formData)
        .then(async ({ data }) => {
          console.log(data.responseData, 'data.responseData');
          if (paymentMethod === 'razor') {
            this.orderCreatedResponse = {
              ...this.orderCreatedResponse,
              id: this.getOrderId(),
              razor: data.responseData,
            }
            this.razorPayInit()
          } else if (paymentMethod === 'paypal') {
            showSuccessNotification(this, 'Please complete the payment process')
            this.paypalPaymentData = data
            window.location.href = this.paypalPaymentData.link
          } else if (paymentMethod === 'phonepe') {
            showSuccessNotification(this, 'Please complete the payment process')
            this.phonePePayment = data.responseData
            window.location.href = this.phonePePayment.redirectURL
          } else if (paymentMethod === 'stripe') {
            this.$refs.stripePaymentModule.orderId = this.getOrderId()
            setTimeout(() => {
              this.$refs.stripePaymentModule.initStripeKey(data.responseData.stripe_publishable_key)
            }, 2000)
          } else if (paymentMethod === 'flutterwave') {
            const params = {
              order_id: this.getOrderId(),
              admin_service: 'SERVICE',
              request_id: this.getOrderId(),
              from_url: window.location.href,
            }
            await makeFlutterwavePayment(params).then(r => {
              if(r.responseData){
                console.log(r.responseData, 'brioj');
                window.location.href = r.responseData.link
              }
            }).catch(e => {
              showDangerNotification(this, "something went wrong")
            })
          } else if (data.statusCode === '200' && paymentMethod === 'cash') {
            showSuccessNotification(this, data.message)
            window.SuperApp.actions.newOrder()
            eventBus.$emit('refresh-on-going-services')
            this.needPayment = false
            this.showOrderPlacedForm = true
          } else if (data.message) {
            showDangerNotification(this, data.message)
          }
          if (paymentMethod !== 'stripe') {
            hideLoader()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    beginPayment(paymentType = '') {
      if (!paymentType && this.orderCreatedResponse.payment) {
        // eslint-disable-next-line no-param-reassign
        paymentType = this.orderCreatedResponse.payment.payment_method
      }
      this.retryPayment(paymentType)
    },
    getUserCardList() {
      const { userCardList } = useUserUi()
      userCardList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.userCards = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    bookTableOnline(payload) {
      this.bookTimeSlot(payload)
    },
    bookTimeSlot(info) {
      const providerInfo = this.getProviderSlug
      const formData = new FormData()
      formData.append('day', info.day)
      formData.append('month', info.month)
      formData.append('provider_id', providerInfo)
      formData.append('time', info.time)
      formData.append('type', this.activeAddress.provider ? 'OFFSITE' : 'ONSITE')
      formData.append('user_id', 32)
      formData.append('year', info.year)
      const { bookServiceTimeslots } = useUserUi()
      showLoader()
      bookServiceTimeslots(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Timeslot booked successfully')
            this.bookedTimeSlotData = data.responseData
            // this.calendarOptions.events = []
            // this.calendarOptions.events.push({
            //   title: `Event #${this.calendarOptions.events.length + 1}`,
            //   date: info.dateStr,
            // })
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getUserPromocode() {
      const { userServicePromocode } = useUserUi()

      userServicePromocode()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.promocodeList = data.responseData
            if (this.orderDetails && this.orderDetails.promocode_id && this.promocodeList) {
              this.paymentInfo.couponCode = this.promocodeList.find(x => x.id === this.orderDetails.promocode_id)
              if (this.paymentInfo.couponCode) {
                this.paymentInfo.isPromocodeApplied = true
                this.isPromocodeVisible = false
              }
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    cancelPromocode() {
      this.isPromocodeVisible = false
      this.paymentInfo.applyPromocode = false
    },
    okPromocode() {
      this.paymentInfo.isPromocodeApplied = true
      this.paymentInfo.applyPromocode = true
      this.isPromocodeVisible = false
      this.getPromoCodeData()
    },
    showPromocode() {
      this.isPromocodeVisible = true
    },
    promocodeChanged() {
      if (this.paymentInfo.applyPromocode) {
        this.showPromocode()
      }
    },
    applyPromocode(coupon) {
      this.paymentInfo.couponCode = coupon
    },
    removePromocode() {
      this.paymentInfo.applyPromocode = false
      this.paymentInfo.couponCode = {}
      this.paymentInfo.isPromocodeApplied = false
      this.getPromoCodeData()
    },
    getPromoCodeData() {
      showLoader()
      let query = ''
      if (this.paymentInfo.couponCode && this.paymentInfo.couponCode.id && this.paymentInfo.isPromocodeApplied) {
        query = `?promocode_id=${this.paymentInfo.couponCode.id}&wallet=0`
      }
      this.$store.dispatch('app-ecommerce/fetchServiceCouponsCartProducts', query).then(response => {
        if (response.data.statusCode === '200') {
          const items = response.data.responseData.carts
          this.paymentInfo.paymentType = ''
          if (this.paymentInfo.isPromocodeApplied) {
            this.paymentInfo.applyPromocode = 'YES'
          } else {
            this.paymentInfo.applyPromocode = 'NO'
          }
          this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', response.data.responseData)
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', items.length)
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', items)
        }
        hideLoader()
      })
    },
    placeOrder() {
      const isBooked = this.$refs.calenderRef.getSubmitPayload().time
      if (!isBooked) {
        this.$swal({
          title: this.$t('Error!'),
          text: this.$t('Please select timeslot to book'),
          iconHtml: this.$helpers.swalIcon('alert.png'),
          customClass: {
            confirmButton: 'btn btn-primary',
            icon: 'border-0',
          },
          buttonsStyling: false,
        })
        return
      }
      this.$swal({
        title: this.$t('You want to book this service?'),
        iconHtml: this.$helpers.swalIcon('schedule.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const formData = new FormData()
          formData.append('promocode_id', this.paymentInfo.couponCode && this.paymentInfo.couponCode.id ? this.paymentInfo.couponCode.id : '')
          formData.append('wallet', '')
          formData.append('payment_mode', this.paymentMode)
          formData.append('payment_method', this.paymentMode === 'CARD' ? this.paymentInfo.paymentType : '')
          formData.append('user_address_id', window.SuperApp.actions.selectedUserAddress())
          formData.append('delivery_date', '')
          formData.append('order_type', '')
          formData.append('onsite', this.activeAddress.provider ? 0 : 1)
          // formData.append('timeslot_id', this.bookedTimeSlotData ? this.bookedTimeSlotData.id : 0)
          const info = this.$refs.calenderRef.getSubmitPayload()
          formData.append('day', info.day)
          formData.append('month', info.month)
          formData.append('provider_id', this.getProviderSlug)
          formData.append('time', info.time)
          formData.append('type', this.activeAddress.provider ? 'OFFSITE' : 'ONSITE')
          formData.append('user_id', window.SuperApp.getters.userInfo().id)
          formData.append('year', info.year)
          this.$store
            .dispatch('app-ecommerce/serviceCheckout', formData)
            .then(response => {
              if (response.data.statusCode === '200') {
                this.fullCartInfo = window.SuperApp.actions.cloneDeep(this.orderDetails)
                this.$store.commit('app-ecommerce/UPDATE_ENTIRE_CART_ITEMS', {})
                this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 0)
                this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS', [])
                this.paymentPending = true
                this.orderId = response.data.responseData.id
                window.history.pushState({}, null, `${this.$route.path}?orderId=${this.orderId}`)
                this.orderNumber = response.data.responseData.booking_id
                this.orderCreatedResponse = response.data.responseData
                eventBus.$emit('refresh-on-going-services')
                // Disable the card payment on services as per Aki request
                const takeCardPayments = false
                if (this.orderCreatedResponse.payment_mode === 'CARD' && takeCardPayments) {
                  this.processOnlinePayments()
                } else {
                  this.needPayment = false
                  this.showOrderPlacedForm = true
                  console.log(this.orderCreatedResponse, 'this.orderCreatedResponse');
                  this.providerData = this.orderCreatedResponse.provider
                  showSuccessNotification(this, 'Order placed successfully')
                  window.SuperApp.actions.newOrder()
                  eventBus.$emit('provider-order-checkout', {
                    orderId: this.orderId,
                    providerId: this.orderCreatedResponse.provider ? this.orderCreatedResponse.provider.id : null,
                    service: this.orderCreatedResponse.admin_service,
                    userInfo: `${this.orderCreatedResponse.user.first_name} ${this.orderCreatedResponse.user.last_name}`,
                    bookingId: this.orderCreatedResponse.booking_id,
                  })
                }
              } else {
                showDangerNotification(this, 'Failed to book service')
              }
              hideLoader(0)
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    processOnlinePayments() {
      if (this.orderCreatedResponse.payment) {
        this.orderCreatedResponse.payment_method = this.orderCreatedResponse.payment.payment_method
      }
      if (this.orderCreatedResponse.payment_method === 'stripe' && this.orderCreatedResponse.payments) {
        this.showPaymentPending = true
        this.needPayment = true
        if (this.$refs.stripePaymentModule) {
          this.$refs.stripePaymentModule.orderId = this.orderCreatedResponse.id
          setTimeout(() => {
            this.$refs.stripePaymentModule.initStripeKey(this.orderCreatedResponse.payments.stripe_publishable_key)
          }, 2000)
        }
      } else if (this.orderCreatedResponse.payment_method === 'razor') {
        this.showPaymentPending = true
        this.needPayment = true
        setTimeout(() => {
          this.razorPayInit()
        }, 2000)
      } else if (this.orderCreatedResponse.payment_method === 'phonepe') {
        this.showPaymentPending = true
        this.needPayment = true
        this.phonePayPayment()
      } else if (this.orderCreatedResponse.payment_method === 'paypal') {
        this.showPaymentPending = true
        this.needPayment = true
        this.paypalPayment()
      }
    },
    goToOrder() {
      this.paymentDone()
    },
    paymentDone() {
      if (this.orderId) {
        this.$router.push({
          path: `/user/service/request/${this.getOrderId()}`,
        })
      }
    },
    razorPayInit() {
      if (!this.orderCreatedResponse) return
      window.razorPayPayload = this
      const options = this.orderCreatedResponse.razor
      options.handler = response => {
        const formData = new FormData()
        formData.append('admin_service', 'SERVICE')
        formData.append('razorpay_payment_id', response.razorpay_payment_id)
        formData.append('razorpay_signature', response.razorpay_signature)
        formData.append('order_id', window.razorPayPayload.orderCreatedResponse.id)

        const { razorPayPaymentService } = useUserUi()

        razorPayPaymentService({
          data: formData,
          query: window.razorPayPayload.orderCreatedResponse.id,
        })
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.showOrderPlacedForm = true
              this.needPayment = false
              eventBus.$emit('refresh-on-going-services')
              window.SuperApp.actions.newOrder()
              showSuccessNotification(window.razorPayPayload, data.message)
            } else {
              window.razorPayPayload.showPaymentFailed()
              showDangerNotification(window.razorPayPayload, data.message)
            }
          })
          .catch(error => {
            window.razorPayPayload.showPaymentFailed()
            showErrorNotification(window.razorPayPayload, error)
          })
      }

      options.theme.image_padding = false
      options.modal = {
        escape: false,
        ondismiss: () => {
          window.razorPayPayload.showPaymentFailed()
          showDangerNotification(window.razorPayPayload, 'Please complete the payment')
        },
      }
      const razorPay = new window.Razorpay(options)
      razorPay.open()
    },
    paypalPayment() {
      const { paypalPaymentService } = useUserUi()

      paypalPaymentService(`${this.orderCreatedResponse.id}/${this.orderCreatedResponse.provider_id}`)
        .then(({ data }) => {
          if (data && data.link) {
            // this.paymentDone()
            this.paypalPaymentData = data
            window.location.href = this.paypalPaymentData.link
            eventBus.$emit('refresh-on-going-services')
            showSuccessNotification(this, data.message ? data.message : 'Please complete the payment')
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(window.razorPayPayload, error)
        })
    },
    showPaymentFailed() {
      this.paymentFailed = true
      this.showPaymentPending = false
      this.showOrderPlacedForm = false
      this.needPayment = false
    },
    phonePayPayment() {
      const { phonePePaymentService } = useUserUi()

      phonePePaymentService({
        query: `${this.orderCreatedResponse.id}/SERVICE`,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            // this.paymentDone()
            this.phonePePayment = data.responseData
            window.location.href = this.phonePePayment.redirectURL
            showSuccessNotification(this, data.message ? data.message : 'Please complete the payment')
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(window.razorPayPayload, error)
        })
    },
    setAddressType(type) {
      if (window.event && window.event.target && window.event.target.classList && window.event.target.classList.contains('feather')) {
        return
      }
      if (type === 'user') {
        this.activeAddress.user = true
        this.activeAddress.provider = false
      } else if (type === 'provider') {
        this.activeAddress.user = false
        this.activeAddress.provider = true
      }
    },
    getServiceTimeslots() {
      const { userServiceTimeslots } = useUserUi()
      const providerInfo = this.getProviderSlug
      userServiceTimeslots(`provider_id=${providerInfo}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.bookedTimeSlots = data.responseData
            window.bookedTimeSlots = this.bookedTimeSlots
            // this.calendarOptions.events = this.bookedTimeSlots.map(x => ({
            //   title: `Service Request No: ${x.service_request_id}`,
            //   date: new Date(x.booking_date),
            // }))
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getTimeFormat(time) {
      const localeSpecificTime = new Date(time).toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      })
      return localeSpecificTime.replace(/:\d+ /, ' ')
    },
    getProviderUserAddress() {
      if (!this.getProviderSlug || !window.SuperApp.actions.selectedUserAddress()) {
        return
      }
      // this.getProviderTimings()
      const { userServiceProviderUserAddress } = useUserUi()
      const providerInfo = this.getProviderSlug
      userServiceProviderUserAddress(`user_address_id=${window.SuperApp.actions.selectedUserAddress()}&provider_id=${providerInfo}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerUserAddress = data.responseData
            // this.providerUserAddress.provider_address.onsite = 1
            if (this.providerUserAddress.provider_address.onsite === 0 || this.providerUserAddress.provider_address.onsite === 2) {
              this.setAddressType('provider')
            } else {
              this.setAddressType('user')
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
</style>

<style lang="scss" scoped>
.payment-successfull {
  h1 {
    color: #88b04b;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
  }
  p {
    color: #404f5e;
    font-size: 20px;
    margin: 0;
  }
  i {
    color: #9abc66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
  }
  &.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    border: 0;
    box-shadow: 0 2px 3px #c8d0d8;
    display: inline-block;
    margin: 0 auto;
  }
}
</style>

<style scoped>
.wrapper-payment-methods img {
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
}

body .w-80px {
  width: 100px;
}

body .w-75px {
  width: 75px;
}
</style>

<style>
.wrapper-payment > div {
  display: flex;
  align-items: center;
}

.user-service-calender .wrapper-payment-info-type > div {
  display: block;
  align-items: center;
  margin-left: 60px;
}

.user-service-calender .wrapper-payment-info-type > div .wrapper-payment {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .wrapper-payment-info-type > div {
    display: block;
  }

  .wrapper-payment-info-type .wrapper-payment > div {
    height: 30px;
  }
}

.wrapper-payment-info-type-retry {
  display: flex;
  align-items: center;
  margin-left: 60px;
}

@media only screen and (max-width: 767px) {
  .wrapper-payment-info-type-retry {
    display: block;
  }

  .wrapper-payment-info-type-retry .wrapper-payment > div {
    height: 30px;
  }
}
</style>

<style scoped>
.common-width-images {
  width: 40px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  border: 1px solid #fff;
}

.service-checkout-right-section {
  background: rgb(247, 247, 247);
  padding-left: 0;
}

.service-checkout-card {
  box-shadow: rgb(34 41 47 / 10%) 0px 4px 24px 0px !important;
  margin-bottom: 15px;
  margin-right: 5px;
  margin-left: 5px;
}

.service-checkout-card table.table th,
.service-checkout-card table.table td {
  padding: 2px 1rem;
}

.user-service-checkout .payment-mode {
  width: 150px;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 768px) {
  .col-md-5-5 {
    flex: 0 0 47%;
    max-width: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .responsive-margin-bottom-checkout {
    margin-bottom: 10px;
  }

  .service-checkout-right-section {
    background: #fff;
    padding-left: 1rem;
  }

  .service-checkout-right-section .card-body {
    padding-left: 8px;
    padding-right: 8px;
  }

  .mobile-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>

<style>
.user-service-calender .custom-radio .custom-control-label::before {
  top: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .user-service-calender .wrap-custom-calender .wrap-calender-header {
    grid-column-gap: 0;
  }

  body #app .user-service-calender .user-service-location-picker {
    margin-bottom: 0 !important;
  }

  body #app .user-service-calender .user-service-location-picker .wrap-top-search-bar {
    display: flex !important;
    width: 100%;
    justify-content: center;
    margin: auto;
    margin-bottom: 10px;
  }

  body #app .user-service-calender .user-service-location-picker {
    display: block !important;
  }
}
</style>
